import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

window.Pusher = Pusher

window.Echo = new Echo(getEchoConfigurationOptions())

interface EchoOptions {
    broadcaster?: 'reverb' | 'pusher'
    key?: string
    cluster?: string
    wsHost?: string
    wsPort?: number
    wssPort?: number
    forceTLS?: boolean
    enabledTransports?: ['ws', 'wss']
}

function getEchoConfigurationOptions(): EchoOptions {
    switch (import.meta.env.VITE_BROADCASTER_DRIVER) {
        case 'reverb':
            return {
                broadcaster: import.meta.env.VITE_BROADCASTER_DRIVER,
                key: import.meta.env.VITE_REVERB_APP_KEY,
                wsHost: import.meta.env.VITE_REVERB_HOST,
                wsPort: import.meta.env.VITE_REVERB_PORT ?? 80,
                wssPort: import.meta.env.VITE_REVERB_PORT ?? 443,
                forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
                enabledTransports: ['ws', 'wss'],
            }

        default:
            return {
                broadcaster: import.meta.env.VITE_BROADCASTER_DRIVER,
                key: import.meta.env.VITE_PUSHER_APP_KEY,
                cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
                forceTLS: true,
            }
    }
}
